export const Shiyu6_1 = [
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 32.14,
    rank: 1,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 8.15,
    rank: 2,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 6.91,
    rank: 3,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 3.01,
    rank: 4,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 2.7,
    rank: 5,
    avg_round: 2.76,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 2.65,
    rank: 6,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 2.47,
    rank: 7,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 1.95,
    rank: 8,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 1.79,
    rank: 9,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 1.74,
    rank: 10,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 1.53,
    rank: 11,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 1.48,
    rank: 12,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 1.25,
    rank: 13,
    avg_round: 2.77,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.96,
    rank: 14,
    avg_round: 2.76,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.91,
    rank: 15,
    avg_round: 2.87,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.91,
    rank: 15,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.86,
    rank: 17,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.8,
    rank: 18,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.78,
    rank: 19,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.78,
    rank: 19,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.73,
    rank: 21,
    avg_round: 2.92,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.73,
    rank: 21,
    avg_round: 2.72,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.73,
    rank: 21,
    avg_round: 2.74,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.73,
    rank: 21,
    avg_round: 2.68,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.62,
    rank: 25,
    avg_round: 2.84,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.6,
    rank: 26,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.57,
    rank: 27,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.57,
    rank: 27,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.55,
    rank: 29,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.47,
    rank: 30,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.47,
    rank: 30,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.44,
    rank: 32,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.42,
    rank: 33,
    avg_round: 2.81,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.42,
    rank: 33,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.31,
    rank: 35,
    avg_round: 2.92,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.31,
    rank: 35,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.29,
    rank: 37,
    avg_round: 2.73,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.29,
    rank: 37,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.29,
    rank: 37,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Seth',
    app_rate: 0.26,
    rank: 40,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.26,
    rank: 40,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.26,
    rank: 40,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.26,
    rank: 40,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.23,
    rank: 44,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.23,
    rank: 44,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Soukaku',
    app_rate: 0.23,
    rank: 44,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.21,
    rank: 47,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.21,
    rank: 47,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.18,
    rank: 49,
    avg_round: 2.57,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Ben',
    char_three: 'Soukaku',
    app_rate: 0.18,
    rank: 49,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 49,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 49,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.18,
    rank: 49,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.18,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.16,
    rank: 57,
    avg_round: 2.6,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.16,
    rank: 57,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.16,
    rank: 57,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.16,
    rank: 57,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 57,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.16,
    rank: 57,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.16,
    rank: 57,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.13,
    rank: 64,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 64,
    avg_round: 2.2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.13,
    rank: 64,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 64,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 64,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 64,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.13,
    rank: 64,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.13,
    rank: 64,
    avg_round: 2.2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.1,
    rank: 72,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 72,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 72,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 72,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 72,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 72,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 72,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 72,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 72,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 72,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 72,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 72,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.1,
    rank: 72,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 85,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 85,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 85,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lycaon',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 85,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 85,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.08,
    rank: 85,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 85,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 85,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Lycaon',
    app_rate: 0.08,
    rank: 85,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 85,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 85,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Corin',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lycaon',
    char_three: 'Seth',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Corin',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.05,
    rank: 108,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.05,
    rank: 108,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Corin',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 108,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Ben',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Piper',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Ellen',
    char_three: 'Corin',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'billy-kid',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Anton',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'soldier-11',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Nekomata',
    char_three: 'jane-doe',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Ellen',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Koleda',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Nekomata',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'jane-doe',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'billy-kid',
    app_rate: 0.03,
    rank: 153,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Anton',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Anton',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Corin',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ben',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 153,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Piper',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 153,
    avg_round: 2,
    star_num: '4'
  }
];
