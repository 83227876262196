export const Shiyu7_2 = [
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 31.55,
    rank: 1,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 18.04,
    rank: 2,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 8.86,
    rank: 3,
    avg_round: 2.68,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 8.06,
    rank: 4,
    avg_round: 2.73,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 5.22,
    rank: 5,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 5.1,
    rank: 6,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 2.74,
    rank: 7,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 1.48,
    rank: 8,
    avg_round: 2.64,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 1.14,
    rank: 9,
    avg_round: 2.77,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.97,
    rank: 10,
    avg_round: 2.77,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.83,
    rank: 11,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.6,
    rank: 12,
    avg_round: 2.68,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.6,
    rank: 12,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.57,
    rank: 14,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.51,
    rank: 15,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.51,
    rank: 15,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.51,
    rank: 15,
    avg_round: 2.14,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.34,
    rank: 18,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.34,
    rank: 18,
    avg_round: 2.58,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.34,
    rank: 18,
    avg_round: 2.42,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.31,
    rank: 21,
    avg_round: 2.4,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.31,
    rank: 21,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.28,
    rank: 23,
    avg_round: 2.2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.28,
    rank: 23,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.28,
    rank: 23,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.26,
    rank: 26,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.26,
    rank: 26,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.2,
    rank: 28,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.2,
    rank: 28,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.17,
    rank: 30,
    avg_round: 2.2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.17,
    rank: 30,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.17,
    rank: 30,
    avg_round: 2.17,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.17,
    rank: 30,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.14,
    rank: 34,
    avg_round: 2.2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.14,
    rank: 34,
    avg_round: 2.4,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 34,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 34,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.14,
    rank: 34,
    avg_round: 2.2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 39,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 39,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 39,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 39,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 39,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 39,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.11,
    rank: 39,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 39,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 39,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Soukaku',
    app_rate: 0.11,
    rank: 39,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Ben',
    char_three: 'Soukaku',
    app_rate: 0.09,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.09,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.09,
    rank: 49,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.09,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.09,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.09,
    rank: 49,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.09,
    rank: 49,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 49,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.09,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.06,
    rank: 59,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 59,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.06,
    rank: 59,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 59,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.06,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.06,
    rank: 59,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 59,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 59,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 59,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 59,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.06,
    rank: 59,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Lycaon',
    app_rate: 0.06,
    rank: 59,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Qingyi',
    app_rate: 0.06,
    rank: 59,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.06,
    rank: 59,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 59,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Anton',
    char_three: 'Soukaku',
    app_rate: 0.06,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 59,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Ben',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ben',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Ben',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'Piper',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Anton',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Anton',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'soldier-11',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Ben',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  }
];
