export const Shiyu7_1 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 23.25,
    rank: 1,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 6.61,
    rank: 2,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 6.55,
    rank: 3,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 4.5,
    rank: 4,
    avg_round: 2.87,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 3.79,
    rank: 5,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 3.68,
    rank: 6,
    avg_round: 2.65,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 2.99,
    rank: 7,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 2.51,
    rank: 8,
    avg_round: 2.76,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 2.25,
    rank: 9,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 1.82,
    rank: 10,
    avg_round: 2.66,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 1.77,
    rank: 11,
    avg_round: 2.77,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 1.6,
    rank: 12,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 1.57,
    rank: 13,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 1.51,
    rank: 14,
    avg_round: 2.76,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 1.34,
    rank: 15,
    avg_round: 2.92,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 1.28,
    rank: 16,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 1.0,
    rank: 17,
    avg_round: 2.84,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 1.0,
    rank: 17,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.97,
    rank: 19,
    avg_round: 2.84,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.88,
    rank: 20,
    avg_round: 2.65,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.85,
    rank: 21,
    avg_round: 2.47,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.85,
    rank: 21,
    avg_round: 2.73,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.8,
    rank: 23,
    avg_round: 2.87,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Seth',
    app_rate: 0.71,
    rank: 24,
    avg_round: 2.81,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.63,
    rank: 25,
    avg_round: 2.55,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.63,
    rank: 25,
    avg_round: 2.77,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.6,
    rank: 27,
    avg_round: 2.64,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.6,
    rank: 27,
    avg_round: 2.81,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.57,
    rank: 29,
    avg_round: 2.21,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.51,
    rank: 30,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.48,
    rank: 31,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.48,
    rank: 31,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.48,
    rank: 31,
    avg_round: 2.43,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.48,
    rank: 31,
    avg_round: 2.81,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.46,
    rank: 35,
    avg_round: 2.42,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.46,
    rank: 35,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.43,
    rank: 37,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.34,
    rank: 38,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.34,
    rank: 38,
    avg_round: 2.58,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.34,
    rank: 38,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.34,
    rank: 38,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.31,
    rank: 42,
    avg_round: 2.56,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.28,
    rank: 43,
    avg_round: 2.7,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.28,
    rank: 43,
    avg_round: 2.44,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.26,
    rank: 45,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.26,
    rank: 45,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.23,
    rank: 47,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.23,
    rank: 47,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.23,
    rank: 47,
    avg_round: 2.43,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.23,
    rank: 47,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.2,
    rank: 51,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 52,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.17,
    rank: 52,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 52,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.17,
    rank: 52,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.17,
    rank: 52,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.17,
    rank: 52,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.17,
    rank: 52,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 52,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 52,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.17,
    rank: 52,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.17,
    rank: 52,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 63,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 63,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.14,
    rank: 63,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.14,
    rank: 63,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 63,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 63,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.14,
    rank: 63,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.14,
    rank: 63,
    avg_round: 2.6,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 63,
    avg_round: 2.6,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.14,
    rank: 63,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 63,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 63,
    avg_round: 2.4,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 63,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lycaon',
    char_three: 'Seth',
    app_rate: 0.11,
    rank: 76,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 76,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 76,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 76,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 76,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Nekomata',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 76,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.11,
    rank: 76,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.09,
    rank: 94,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.09,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.09,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.09,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.09,
    rank: 94,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.09,
    rank: 94,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.09,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.09,
    rank: 94,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.09,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.09,
    rank: 94,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lycaon',
    app_rate: 0.09,
    rank: 94,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.09,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.09,
    rank: 94,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 94,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 110,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Lycaon',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.06,
    rank: 110,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'Ben',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Corin',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.06,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.06,
    rank: 110,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'billy-kid',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Corin',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Corin',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Corin',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ellen',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Nekomata',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'soldier-11',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'soldier-11',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'soldier-11',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'billy-kid',
    char_three: 'jane-doe',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Corin',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Corin',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Koleda',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Nekomata',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Anton',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Anton',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Corin',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ben',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 145,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 145,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 145,
    avg_round: 1,
    star_num: '4'
  }
];
