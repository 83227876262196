export const Shiyu6_2 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 41.87,
    rank: 1,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 16.56,
    rank: 2,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 7.32,
    rank: 3,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 2.21,
    rank: 4,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 2.15,
    rank: 5,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 2.15,
    rank: 5,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 1.69,
    rank: 7,
    avg_round: 2.72,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 1.53,
    rank: 8,
    avg_round: 2.69,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 1.43,
    rank: 9,
    avg_round: 2.92,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 1.43,
    rank: 9,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.67,
    rank: 11,
    avg_round: 2.54,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.65,
    rank: 12,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.57,
    rank: 13,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.57,
    rank: 13,
    avg_round: 2.41,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.52,
    rank: 15,
    avg_round: 2.77,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.49,
    rank: 16,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.47,
    rank: 17,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.47,
    rank: 17,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.47,
    rank: 17,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.44,
    rank: 20,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.44,
    rank: 20,
    avg_round: 2.92,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.42,
    rank: 22,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.42,
    rank: 22,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.39,
    rank: 24,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.36,
    rank: 25,
    avg_round: 2.29,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.36,
    rank: 25,
    avg_round: 2.55,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.36,
    rank: 25,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.36,
    rank: 25,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.36,
    rank: 25,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.34,
    rank: 30,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.31,
    rank: 31,
    avg_round: 2.14,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.29,
    rank: 32,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.26,
    rank: 33,
    avg_round: 2.7,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.26,
    rank: 33,
    avg_round: 2.7,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.23,
    rank: 35,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.23,
    rank: 35,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.23,
    rank: 35,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.21,
    rank: 38,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.21,
    rank: 38,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.21,
    rank: 38,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.21,
    rank: 38,
    avg_round: 2.29,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.21,
    rank: 38,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 43,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 44,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.16,
    rank: 44,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 44,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.16,
    rank: 44,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.16,
    rank: 44,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.16,
    rank: 44,
    avg_round: 2.6,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 44,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.16,
    rank: 44,
    avg_round: 2.4,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.16,
    rank: 44,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.13,
    rank: 53,
    avg_round: 2.4,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.13,
    rank: 53,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.13,
    rank: 53,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 53,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 53,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.13,
    rank: 53,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 53,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.13,
    rank: 53,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 53,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 53,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 63,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 63,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.1,
    rank: 63,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 63,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.1,
    rank: 63,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 68,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.08,
    rank: 68,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 68,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Nekomata',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 68,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 68,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 68,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 68,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 68,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 68,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 68,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 68,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 68,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 68,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 68,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Ben',
    app_rate: 0.05,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ben',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Ben',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'billy-kid',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'soldier-11',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Ben',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'Piper',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Anton',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'soldier-11',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Anton',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 105,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Ben',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  }
];
